<template>
  <div
    v-bind:style="borderColor"
    v-infocus="'showElement'"
    class="hidden hidden-down anuncio-container"
  >
    <img
      class="icon_lg circular-img"
      v-bind:src="creadorImage"
      alt="profile image"
    />
    <div class="anuncio-info">
      <div class="anuncio-info__header">
        <h2>{{ anuncio.titulo }}</h2>
        <button
          v-if="userIsPerformance"
          class="anuncio__action-button"
          @click.stop="editar()"
        >
          <Icon name="edit" style="fontSize: 1.4rem" color="000" />
        </button>
        <button
          v-if="userIsPerformance"
          class="anuncio__action-button"
          @click.stop="eliminar()"
        >
          <Icon name="delete" style="fontSize: 1.4rem" color="000" />
        </button>
      </div>
      <p>
        {{ limitedDescription }}
      </p>
      <a :href="anuncio.link" target="_blank" rel="noopener">Leer mas</a>
    </div>
  </div>
</template>
<script>
import helpers from '@/mixins/helpers';
import Icon from "@/components/Icon.vue";

export default {
  components: { Icon },
  props: {
    anuncio: Object
  },
  mixins: [helpers],
  computed: {
    creadorImage() {
      return this.anuncio?.creador?.imagen
        ? this.$localurl + "/" + this.anuncio.creador.imagen
        : require("../assets/img/default.png");
    },
    borderColor() {
      const color = this.anuncio?.color;
      return "border-left: 16px solid " + (color || "#00A3D9");
    },
    limitedDescription() {
      return this.anuncio.descripcion?.length > 150
        ? this.anuncio.descripcion.slice(0, 150) + "..."
        : this.anuncio.descripcion;
    }
  },
  methods: {
    eliminar() {
      const that = this;
      this.$axios
        .delete(this.$localurl + "/api/anuncio/" + this.anuncio.id)
        .then(function() {
          that.$eventHub.$emit("anuncio-nuevo");
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    editar() {
      this.$eventHub.$emit("anuncio-editar", this.anuncio);
    },
    irALink() {
      window.open(this.anuncio.link, "_blank").focus();
    }
  }
};
</script>
<style scoped src="../assets/css/components/anuncio.css"></style>
