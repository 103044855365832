<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="novedad">
        <label class="novedad-title">Publicar</label>
        <div class="novedad-body">
          <div>
            <div class="novedad-row">
              <label>Portada</label>
              <div
                class="novedad-imagen"
                @click="cambiarImagen()"
                :class="{ 'error-input': errorImagen }"
                :style="{ backgroundColor: has_image ? '#21a0d2 ' : '' }"
              >
                <span v-if="has_image == null" class="material-symbols-outlined">
                  add_photo_alternate
                </span>
                <span
                  v-if="has_image != null"
                  class="material-symbols-outlined"
                  :style="{ color: has_image ? '#FFF ' : '' }"
                >
                  delete
                </span>
                <label v-if="has_image == null"
                  >Arrastra o selecciona archivo</label
                >
                <label
                  v-if="has_image != null"
                  :style="{ color: has_image ? '#FFF ' : '' }"
                  >{{ nombreImagen }}</label
                >
              </div>
              <input
                class="app-hide"
                type="file"
                ref="imagenUpload"
                @change="changeImagen()"
                accept="image/png, image/jpeg"
              />
              <p
                v-for="(error, index) in activeCoverImageErrors"
                :key="index"
                class="cover-img--error"
              >
                {{ error.message }}
              </p>
            </div>
            <div class="novedad-row">
              <label
                >Titulo del anuncio
                <span
                  :class="
                    tituloLongitudRestante > 0
                      ? 'novedad-longitud'
                      : 'novedad-longitud-error'
                  "
                  >{{ tituloLongitudRestante }} Caracteres</span
                >
              </label>
              <input
                type="text"
                placeholder="Escribe el título del anuncio"
                v-model="titulo"
                class="input"
                :class="{ 'error-input': errorTitulo }"
              />
              <LabelCheckbox
                v-model="replicarAsunto"
                text="Replicar en el asunto del mail"
              ></LabelCheckbox>
            </div>
            <div class="novedad-row">
              <label
                >Descripción
                <span
                  :class="
                    descripcionLongitudRestante > 0
                      ? 'novedad-longitud'
                      : 'novedad-longitud-error'
                  "
                  >{{ descripcionLongitudRestante }} Caracteres</span
                >
              </label>
              <textarea
                type="text"
                placeholder="Escribe una breve descripción"
                v-model="descripcion"
                :class="{ 'error-input': errorDescripcion }"
              ></textarea>
            </div>
            <div class="novedad-row">
              <LinksAdd
                :links="this.links"
                @addLink="onAddLink"
                @removeLink="onRemoveLink"
                @updateLink="onUpdateLink"
              ></LinksAdd>
            </div>
          </div>
          <div>
            <span
              class="material-symbols-outlined novedad-cerrar"
              @click="cerrar()"
            >
              close
            </span>
            <div class="novedad-row">
              <label>Visibilidad</label>

              <SelectUsers></SelectUsers>
            </div>
            <div class="novedad-fijar">
              <LabelCheckbox @change="onChangeDestacar" text="Destacar"></LabelCheckbox>
              <LabelCheckbox
                v-model="avisarPorMail"
                text="Aviso por mail"
              ></LabelCheckbox>
              <LabelCheckbox
                v-model="fijar"
                text="Fijar posición"
              ></LabelCheckbox>
            </div>
            <div class="novedad-row">
              <label>Vista previa</label>
              <div class="novedad-previa-container">
                <NovedadVistaPrevia
                  :destacar="destacar"
                  :titulo="titulo"
                  :descripcion="descripcion"
                  :imagen="imagen.src"
                ></NovedadVistaPrevia>
              </div>
            </div>
            <div class="novedad-btn-container">
              <button
                class="novedad-btn novedad-btn--cancelar"
                @click="cerrar()"
              >
                Cancelar
              </button>
              <button
                class="novedad-btn novedad-btn--publicar"
                @click="guardar()"
              >
                Publicar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LinksAdd from "@/components/LinksAdd.vue";
import LabelCheckbox from "@/components/form/LabelCheckbox.vue";
import NovedadVistaPrevia from "@/components/novedad/NovedadVistaPrevia.vue";
import SelectUsers from "./SelectUsers.vue";

export default {
  name: "novedadpopup",
  components: { SelectUsers, LinksAdd, LabelCheckbox, NovedadVistaPrevia },
  data() {
    return {
      idElegido: null,
      mostrar: false,
      roles: [
        {
          id: "distribuidor_gerente",
          detalle: "Distribuidor - Dueño/Gerente/Director"
        },
        {
          id: "distribuidor_administrativo",
          detalle: "Distribuidor - Administrativo"
        },
        {
          id: "distribuidor_vendedor",
          detalle: "Distribuidor - Vendedor"
        },
        {
          id: "planes_comerciales",
          detalle: "Planes Comerciales"
        },
        {
          id: "basf",
          detalle: "BASF"
        },
        {
          id: "todos",
          detalle: "Todos"
        }
      ],
      rolElegido: null,
      titulo: "",
      tituloMax: 255,
      descripcion: "",
      descripcionMax: 255,
      imagen: {
        src: null,
        width: null
      },
      nombreImagen: "",
      errorImagen: false,
      errorTitulo: false,
      errorDescripcion: false,
      fijar: false,
      replicarAsunto: false,
      avisarPorMail: false,
      destacar: false,
      links: [{ name: "", url: "" }],
      type: "",
      errors: {
        coverImage: {
          width: {
            state: false,
            message:
              "*La imagen debe tener un ancho mínimo de 1440px para garantizar una correcta visualización en la mayoría de las pantallas."
          }
        }
      }
    };
  },
  created() {},
  mounted() {
    this.errors.coverImage.width.state = false;
    this.$eventHub.$on(
      "novedad-abrir",
      ({ type }) => ((this.mostrar = true), (this.type = type))
    );
    this.$eventHub.$on("novedad-editar", novedad => this.editar(novedad));
  },
  computed: {
    has_image() {
      return this.imagen.src !== null;
    },
    descripcionLongitudRestante() {
      return this.descripcionMax - this.descripcion.length;
    },
    tituloLongitudRestante() {
      return this.tituloMax - this.titulo.length;
    },
    activeCoverImageErrors() {
      return Object.values(this.errors.coverImage).filter(error => error.state);
    }
  },
  methods: {
    onAddLink(newLink) {
      this.links.push(newLink);
    },
    onRemoveLink(id) {
      this.links = this.links.filter(link => link.id !== id);
    },
    onUpdateLink(index, updatedLink) {
      this.$set(this.links, index, updatedLink);
    },
    onChangeDestacar() {
      this.destacar = !this.destacar;
      if(this.has_image){
        this.isImageWidthValid(this.imagen);
      }
    },
    cerrar() {
      this.mostrar = false;
      this.imagen = {
        src: null,
        width: null
      };
      this.nombreImagen = "";
      this.titulo = "";
      this.descripcion = "";
      this.errorImagen = false;
      this.errorTitulo = false;
      this.errorDescripcion = false;
      this.idElegido = null;
      this.rolElegido = null;
      this.fijar = false;
      this.replicarAsunto = false;
      this.avisarPorMail = false;
      this.destacar = false;
      this.links = [{ name: "", url: "" }];
    },
    editar(novedad) {
      this.imagen.src = novedad.imagen;
      this.nombreImagen = novedad.imagenNombre;
      this.titulo = novedad.titulo;
      this.descripcion = novedad.descripcion;
      this.idElegido = novedad.id;
      this.fijar = novedad.fijar;
      this.mostrar = true;
      this.links = novedad.links;
    },
    updateDropdownRoles(payload) {
      if (payload.id != "todos") {
        this.rolElegido = payload.id;
      } else {
        this.rolElegido = null;
      }
    },
    cambiarImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      const file = this.$refs.imagenUpload.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Validar el ancho de la imagen
          if (!this.isImageWidthValid(img)) {
            return;
          }
          this.processImage();
          // Si todo está bien, asignar la imagen
          this.$nextTick(() => {
            this.imagen = {
              src: e.target.result,
              width: img.width
            };
          });
        };
      };
      reader.readAsDataURL(file);
    },

    // Validación del ancho de la imagen (debe ser al menos 1280px si es destacada)
    isImageWidthValid(img) {
      const MIN_WIDTH = 1440; // Ancho mínimo permitido
      const isValid = (img.width >= MIN_WIDTH && this.destacar) || !this.destacar;
      if (!isValid) {
        this.errors.coverImage.width.state = true;
      } else {
        this.errors.coverImage.width.state = false;
      }
      return isValid;
    },
    processImage() {
      // Read in file
      let file = this.$refs.imagenUpload.files[0];
      this.nombreImagen = file.name;
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function(readerEvent) {
          let image = new Image();
          image.onload = function() {
            // Resize the image
            const MAX_SIZE = 1440;

            let canvas = document.createElement("canvas"),
              width = image.width,
              height = image.height;

            if (width > height) {
              if (width > MAX_SIZE) {
                height *= MAX_SIZE / width;
                width = MAX_SIZE;
              }
            } else {
              if (height > MAX_SIZE) {
                width *= MAX_SIZE / height;
                height = MAX_SIZE;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen.src = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    guardar() {
      let continuar = true;

      if (this.has_image == null || this.activeCoverImageErrors.length > 0) {
        this.errorImagen = true;
        continuar = false;
      } else {
        this.errorImagen = false;
      }

      if (this.titulo == "" || this.tituloLongitudRestante < 0) {
        this.errorTitulo = true;
        continuar = false;
      } else {
        this.errorTitulo = false;
      }

      if (this.descripcion == "" || this.descripcionLongitudRestante < 0) {
        this.errorDescripcion = true;
        continuar = false;
      } else {
        this.errorDescripcion = false;
      }
      this.links.forEach((link, index) => {
        if (link.name == "" || link.url == "") {
          this.$set(this.links[index], "error", true);
          continuar = false;
        } else {
          this.$set(this.links[index], "error", false);
        }
      });
      if (continuar) {
        this.links = this.links.map(link => {
          if (link.url && !link.url.includes("https://")) {
            link.url = `https://${link.url}`;
          }
          return {
            name: link.name,
            url: link.url
          };
        });
        let json = {
          id: this.idElegido,
          imagen: this.imagen.src,
          imagenNombre: this.nombreImagen,
          titulo: this.titulo,
          descripcion: this.descripcion,
          links: this.links,
          rol: "ROL_ADMIN",
          fijar: this.fijar,
          asuntoMail: this.replicarAsunto,
          avisarPorMail: this.avisarPorMail,
          destacar: this.destacar,
          appScope: Object.entries(this.$store.state.tools.selected)
            .filter(([, value]) => value) // Filtra las entradas con valor `true`
            .map(([key]) => key), // Extrae las claves,
          internalRoleScope: Object.entries(this.$store.state.roles.selected)
            .filter(([, value]) => value) // Filtra las entradas con valor `true`
            .map(([key]) => key),
          type: this.type
        };
        console.log(json);
        const that = this;
        this.$axios
          .put(this.$localurl + "/api/novedad", json)
          .then(function() {
            if (json.type === "NOVEDAD") {
              that.$eventHub.$emit("novedad-nuevo");
            } else {
              that.$eventHub.$emit("multimedia-nuevo");
            }
          })
          .catch(function(response) {
            console.log(response);
          });
        this.cerrar();
      }
    }
  }
};
</script>

<style scoped src="../../assets/css/components/novedadpopup.css"></style>
