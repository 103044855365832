export default {
  data() {
    return {
      localUser: null,
      clientModuleAcceses: [],
    };
  },
  mounted() {
    this.$eventHub.$on("update", usuario => {
      this.localUser = usuario;
      if (this.localUser.rolDefault.rol == "Distribuidor") {
        this.getClientModulesAcceses();
      }
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("update");
  },
  computed: {
    hasAccessToCYO() {
      return (
        this.localUser?.rolDefault.rol != "Distribuidor" ||
        this.clientModuleAcceses.some(
          access => access.module === "CYO" && this.localUser.rolCYO != null
        )
      );
    },
    hasAccessToCC() {
      return (
        this.localUser?.rolDefault.rol != "Distribuidor" ||
        this.clientModuleAcceses.some(
          access => access.module === "CC" && this.localUser.rolCC != null
        )
      );
    },
    hasAccessToPOG() {
      return (
        this.localUser?.rolDefault.rol != "Distribuidor" ||
        this.clientModuleAcceses.some(
          access => access.module === "POG" && this.localUser.rolPOG != null
        )
      );
    },
    getToolsFiltered() {
      // es necesario que tenga el user setteado
      if (!this.localUser) return [];

      const modules = [
        {
          isVisible: this.userIsPerformance,
          class: "permisos-card",
          icon: "lock_open",
          text: "Permisos",
          onClick: () => this.goToPermisos()
        },
        {
          isVisible: this.hasAccessToCYO,
          class: "micyo-card",
          icon: "potted_plant",
          text: "Mi Cuenta<br />& Orden",
          onClick: () => this.checkGoTo("cyo")
        },
        {
          isVisible: this.hasAccessToCC,
          class: "micc-card",
          icon: "export_notes",
          text: "Mi Cuenta<br />Corriente",
          onClick: () => this.checkGoTo("cc")
        },
        {
          isVisible: this.localUser.rolPedidos != null,
          class: "misimulador-card",
          icon: "local_shipping",
          text: "Mis<br />Pedidos",
          onClick: () => this.goToModule("mispedidos")
        },
        {
          isVisible: this.hasAccessToPOG,
          class: "mipog-card",
          icon: "inventory_2",
          text: "Mi<br />POG",
          onClick: () => this.checkGoTo("pog")
        },
        {
          isVisible: this.localUser.rolPlanComercial != null,
          class: "misimulador-card",
          icon: "description",
          text: "Mi Plan<br />Comercial",
          onClick: () => this.goToModule("simulador")
        },
        {
          isVisible: this.miTiendaToken,
          class: "mi-tienda-card",
          icon: "shopping_bag",
          text: "Mi Tienda",
          onClick: () => this.goToModule("mitienda")
        },
        {
          isVisible: true,
          class: "web-oficial-card",
          icon: "captive_portal",
          text: "Web Oficial",
          onClick: () => this.goToModule("weboficial")
        },
        {
          isVisible: this.userIsPerformance,
          class: "metricas-card",
          icon: "clock_loader_90",
          text: "Métricas",
          onClick: () => this.goToMetricas()
        }
      ];
      return modules.filter(module => module.isVisible);
    }
  },
  methods: {
    async getClientModulesAcceses() {
      const response = await this.$axios.get(
        this.$localurl + `/api/client-access?soldTo=${this.localUser.codigo}`
      );
      this.clientModuleAcceses = [...response.data];
    },
    checkGoTo(text) {
      this.irAModulo = text;
      switch (text) {
        case "cc":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goToModule(text);
          } else {
            this.goToTitle = "cuenta corriente";
            this.$refs.selectClientPopup.open();
          }
          break;
        case "pog":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goToModule(text);
          } else {
            this.goToTitle = "pog";
            this.$refs.selectClientPopup.open();
          }
          break;

        case "cyo":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goToModule(text);
          } else {
            const that = this;
            this.$axios
              .get(this.$localurl + "/api/cyo/muchos-clientes")
              .then(function() {
                that.goToModule(text);
              })
              .catch(function() {
                that.goToTitle = "cuenta y orden";
                that.$refs.selectClientPopup.open();
              });
          }
          break;
        default:
          break;
      }
    },
    goToModule(module) {
      this.postMetric(module);
      let token = localStorage.getItem("token-landing");
      // solo pasamos landing y cyo a prod
      let ruta = "";
      switch (module) {
        case "mialta":
          token = localStorage.getItem("token");
          ruta = this.$mialta_url + "/landing/" + token;
          break;
        case "cyo":
          token = localStorage.getItem("token");
          ruta = this.$cyo_url + "/landing/" + token;
          break;
        case "mispedidos":
          ruta = this.$mispedidos_url + "/landing?auth=" + token;
          break;
        case "cc":
          token = localStorage.getItem("token");
          ruta = this.$cc_url + "/landing/" + token;
          break;
        case "pog":
          token = localStorage.getItem("token");
          ruta = this.$pog_url + "/landing/" + token;
          break;
        case "simulador":
          ruta = this.$dev
            ? "https://dev.micuenta.basf.com.ar/simulador"
            : "https://micuenta.agro.basf.com/simulador";
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            ruta =
              ruta +
              "/plancomercial/planificacion?auth=" +
              token +
              "&admin=true";
          } else ruta = ruta + "/landing?auth=" + token;
          break;
        case "documentos":
          token = localStorage.getItem("token");
          if (this.$dev) {
            ruta =
              "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
          } else {
            ruta =
              "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
          }
          break;
        case "mitienda":
          window
            .open(
              "https://mitiendabasf.com.ar?token=" + this.miTiendaToken,
              "_blank"
            )
            .focus();
          break;
        case "weboficial":
          window
            .open("https://agriculture.basf.com/ar/es.html", "_blank")
            .focus();
          break;
        default:
          break;
      }
      if (ruta) {
        window.location.href = ruta;
      }
    },
    goToPermisos() {
      this.$router.push("/permisos");
    },
    goToMetricas() {
      this.$router.push("/metricas");
    },
    postMetric(modulo) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + modulo)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    }
  }
};
